import React from "react";

import { styled } from "@mui/material/styles";

import CardHeader from "@mui/material/CardHeader";

import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import { Favorite, Share, ExpandMore, MoreVert } from "@mui/icons-material";

import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  Button,
  Box,
  Stack,
  Grid,
  Container,
} from "@mui/material";
import Products, { ProductsCard } from "./Products";

const Services = () => {
  return (
    <Container
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        positon: "relative",
      }}
    >
      <Grid container spacing={6}>
        <Grid item lg={4} md={5} sm={12} xs={12}>
          <RecipeReviewCard />
        </Grid>
        <Grid item lg={8} md={7} sm={12} xs={12}>
          <Products />
        </Grid>
      </Grid>

      {/* <RecipeReviewCard />
            <RecipeReviewCard />
            
            <ServicesCard /> */}

      {/* <ServicesCard />
            <ServicesCard /> */}
      {/* <ServicesCard /> <ServicesCard />            <ServicesCard /> */}
    </Container>
  );
};

function ServicesCard() {
  return (
    <Box>
      <Card sx={{ maxWidth: "100%", minWidth: "260px", mb: 2 }}>
        <CardMedia
          component="img"
          height="194"
          image="./img/Indian-Sugar-Industry.jpg"
          alt="Paella dish"
        />
        <CardContent>
          <Typography variant="inherit" color="red">
            We are the largest sugarcane crusher manufacturer of India. Our
            manufacturing experience since 1908 makes Chindipir Sugarcane
            Supplier crusher a global leader in this sector. We have a wide
            range of crushers and accessories to meet any type of requirements
            of our valuable customers. We are the largest sugarcane crusher
            manufacturer of India. Our manufacturing experience since 1988 makes
            OM KAILASH sugarcane crusher a global leader in this sector. We have
            a wide range of crushers and accessories to meet any type of
            requirements of our valuable customers. .
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
}

const ExpandMoref = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

function RecipeReviewCard() {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Box sx={{ width: "100%", margin: "auto", maxWidth: 450 }}>
      <Card sx={{ width: "inherit", height: "auto" }}>
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: "#000" }} aria-label="recipe">
              R
            </Avatar>
          }
          action={
            <IconButton aria-label="settings">
              <MoreVert />
            </IconButton>
          }
          title="Chindipir Sugarcane Supplier"
          subheader="Shadbal, Dist. - Belgavi"
        />
        <Box style={{ display: "flex", justifyContent: "center" }}>
          {" "}
          <img
            height="194"
            src="./img/Indian-Sugar-Industry.jpg"
            alt="Paella dish"
            width="auto"
          />
        </Box>
        <CardContent>
          <Typography paragraph fontSize={15} color="text.secondary">
            We are the largest sugarcane crusher manufacturer of India. Our
            manufacturing experience since 1908 makes Chindipir Sugarcane
            Supplier crusher a global leader in this sector. We have a wide
            range of crushers and accessories to meet any type of requirements
            of our valuable customers. We are the largest sugarcane crusher
            manufacturer of India. Our manufacturing experience since 1988 makes
            OM KAILASH sugarcane crusher a global leader in this sector. We have
            a wide range of crushers and accessories to meet any type of
            requirements of our valuable customers. We have a wide range of
            crushers and accessories to meet any type of requirements of our
            valuable customers. We have a wide range of crushers and a .
          </Typography>
        </CardContent>
        <CardActions disableSpacing>
          <IconButton aria-label="add to favorites">
            <Favorite />
          </IconButton>
          <IconButton aria-label="share">
            <Share />
          </IconButton>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoref />
          </ExpandMore>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            {/* <Typography paragraph>Method:</Typography> */}
          </CardContent>
        </Collapse>
      </Card>
    </Box>
  );
}

export default Services;
