import React, { useEffect, useState } from 'react'
import Carousel from 'react-bootstrap/Carousel';

import 'bootstrap/dist/css/bootstrap.css';
import '../css/Slider.css'

const ImgCarousel = () => {
  const [SliderI, setSliderI] = useState([])

  useEffect(() => {
    setSliderI([{ url: "./img/slider/IMG-20230811-WA0048.jpg" }, { url: "./img/slider/IMG-20230811-WA0049.jpg" }, { url: "./img/slider/IMG-20230811-WA0050.jpg" }, { url: "./img/slider/IMG-20230811-WA0051.jpg" }, { url: "./img/slider/IMG-20230811-WA0052.jpg" }])
  }, [])
  var Item = SliderI.map((img, i) => {
    console.log(img)
    return (
      <Carousel.Item key={i}>

        <img
          className="d-block w-100"
          src={img.url}
          alt={img}
          style={{ maxHeight: '65vh', backgroundColor: 'red' }}
        />

      </Carousel.Item>

    )
  })

  return (
    <>
      <Carousel style={{ minWidth: '100vw' }}>

        {Item}
      </Carousel>
    </>
  )
}

export default ImgCarousel