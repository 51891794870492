import { Button, FormControl, TextField, Modal, Box } from '@mui/material'

import React, { useState, useEffect } from 'react'
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import '../css/MessageModal.css'

const MessageModalData = (props) => {

    const [openModel, setOpenModel] = useState(false);
    const handleOpenModel = () => setOpenModel(true);
    const handleCloseModel = () => setOpenModel(false);
    useEffect(() => {
        setOpenModel(props.enqModal)
    })
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: 430,
        minWidth: 260,
        bgcolor: 'background.paper',
        borderRadius: 2,
        border: 'none',
        boxShadow: 2,
        p: 2.5,
        outline: 'none',
    };

    useEffect(() => {
        setOpenModel(true)

    }, [])
    return (
        <Modal
            openModel={openModel}
            onClose={() => handleCloseModel}

        >
            <Box sx={style}>
                <MessageModalData />
            </Box>
        </Modal>


    )
}

function MessageModal() {
    const [phone, setPhone] = useState()

    return (

        <FormControl sx={{ width: "100%" }}>

            <img
                srcSet="./img/sendmessage.PNG"
                src="./img/sendmessage.PNG"
                alt='as'
                loading="lazy"
                width={150}
                padding-bottom={3}


            />

            <PhoneInput
                defaultCountry="ind"
                value={phone}

                onChange={(phone) => setPhone(phone)}
            />
            <br />
            <TextField id="standard-basic" label="Message" variant="outlined" multiline rows={4} fullWidth />
            <br />
            <Button variant="contained" color="primary">Send</Button>
        </FormControl>
    )
}

export default MessageModal