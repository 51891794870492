import { Container, Button, Modal, Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Header from "./components/Header";
import ImgCarousel from "./components/ImgCarousel";
import Services from "./components/Services";
import "./css/Style.css";
import About from "./components/About";
import { BrowserRouter } from "react-router-dom";
import { Route } from "@mui/icons-material";
import MessageModal from "./components/MessageModal";
import Footer from "./components/Footer";

function App() {
  return (
    <>
      <Header />
      <ImgCarousel />

      <Container sx={{ mt: 6, mb: 6, maxWidth: 1350 }}>
        <Services />
        <br />

        <About />
      </Container>
      <Footer />
    </>
  );
}
export default App;
