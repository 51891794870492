import React from 'react'
import { Card, CardMedia, CardContent, Typography, CardActions, Button, Box, Container, Paper } from '@mui/material'

const About = () => {
    return (
        <Container style={{ mt: 6 }}>
            <AboutCard />
        </Container>
    )
}


export const AboutCard = () => {
    return (
        <Box>
            <Paper elevation={4}>
                <Card >

                    <CardContent>
                        <Typography gutterBottom variant="h6" fontSize={19} component="div">
                            SugarCane Policy

                        </Typography>
                        <Typography paragraph fontSize={15} color="text.secondary">

                            Sugar industry is an important agro-based industry that impacts rural livelihood of about 50 million sugarcane farmers and around 5 lakh workers directly employed in sugar mills. Employment is also generated in various ancillary activities relating to transport, trade servicing of machinery and supply of agriculture inputs. India is the second largest producer of sugar in the world after Brazil and is also the largest consumer. Today Indian sugar industry’s annual output is worth approximately Rs.80,000 crores.
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button size="small">Share</Button>
                        <Button size="small">Learn More</Button>
                    </CardActions>
                </Card>
            </Paper>
        </Box>

    )
}

export default About
