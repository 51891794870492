import { useState } from "react";
import { TextField, Button, Typography, Grid, Box, Container, Paper, Hidden } from "@mui/material";

export default function Vehicle() {
    const [data, setData] = useState({
        vehicleName: '',
        vehicleModal: '',
        vehicleNo: '',
        vehicleRegNo: '',
        VehicleRegDate: '',

        ownerName: '',
        ownerMobile: '',
        driverName: '',
        driverMobile: '',
        driverLicenceNo: '',
    });

    const [DateInputClick, setDateInputClick] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        //
    };

    return (
        <Container style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Paper elevation={6} style={{ maxWidth: '580px' }}>
                <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ height: "100%" }}
                >
                    <Grid item xs={12} md={4} >
                        <Box sx={{ p: 2 }}>
                            <Typography variant="h4" align="left" mb={4} mt={3}>
                                Vehicle Form
                            </Typography>
                            <form onSubmit={handleSubmit}>

                                <Box>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <TextField
                                                fullWidth
                                                label="Vehicle Name"
                                                value={data.vehicleName}
                                                onChange={(e) => setData({ ...data, vehicleName: e.target.value })}
                                                margin="normal"
                                                required
                                                size="small"
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6} md={6}>
                                            <TextField
                                                fullWidth
                                                label="Owner Name"
                                                value={data.ownerName}
                                                onChange={(e) => setData({ ...data, ownerName: e.target.value })}
                                                margin="normal"
                                                required
                                                size="small"
                                            />
                                        </Grid>


                                        <Grid item xs={12} sm={6} md={6}>
                                            <TextField
                                                fullWidth
                                                label="Vehicle Modal"
                                                value={data.vehicleModal}
                                                onChange={(e) => setData({ ...data, vehicleModal: e.target.value })}
                                                margin="normal"
                                                type="text"
                                                required
                                                size="small"
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6} md={6}>
                                            <TextField
                                                fullWidth
                                                label="Owner Mobile"
                                                value={data.ownerMobile}
                                                onChange={(e) => setData({ ...data, ownerMobile: e.target.value })}
                                                margin="normal"
                                                type="number"
                                                required
                                                size="small"
                                            />
                                        </Grid>


                                        <Grid item xs={12} sm={6} md={6}>
                                            <TextField
                                                fullWidth
                                                label="Vehicle No."
                                                value={data.vehicleNo}
                                                onChange={(e) => setData({ ...data, vehicleNo: e.target.value })}
                                                margin="normal"
                                                type="text"
                                                required
                                                size="small"
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6} md={6}>
                                            <TextField
                                                fullWidth
                                                label="Driver Name"
                                                value={data.driverName}
                                                onChange={(e) => setData({ ...data, driverName: e.target.value })}
                                                margin="normal"
                                                type="text"
                                                required
                                                size="small"
                                            />
                                        </Grid>


                                        <Grid item xs={12} sm={6} md={6}>
                                            <TextField
                                                fullWidth
                                                label="Vehicle Registration No."
                                                value={data.vehicleRegNo}
                                                onChange={(e) => setData({ ...data, vehicleRegNo: e.target.value })}
                                                margin="normal"
                                                type="text"
                                                required
                                                size="small"
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6} md={6}>
                                            <TextField
                                                fullWidth
                                                label="Driver Mobile"
                                                value={data.driverMobile}
                                                onChange={(e) => setData({ ...data, driverMobile: e.target.value })}
                                                margin="normal"
                                                type="number"
                                                required
                                                size="small"
                                            />
                                        </Grid>


                                        <Grid item xs={12} sm={6} md={6}>
                                            <TextField
                                                fullWidth
                                                label="Vehicle Registration Date"
                                                value={data.VehicleRegDate}
                                                onChange={(e) => setData({ ...data, VehicleRegDate: e.target.value })}
                                                margin="normal"
                                                onClick={() => setDateInputClick(true)}
                                                onBlur={() => setDateInputClick(false)}
                                                type={DateInputClick ? 'date' : 'text'}
                                                required
                                                size="small"
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6} md={6}>
                                            <TextField
                                                fullWidth
                                                label="Drive Licence"
                                                value={data.driverLicenceNo}
                                                onChange={(e) => setData({ ...data, driverLicenceNo: e.target.value })}
                                                margin="normal"

                                                required
                                                size="small"
                                            />
                                        </Grid>

                                    </Grid>
                                    <Button variant="contained" type="submit" fullWidth sx={{ mt: 3.5, mb: 2 }}>
                                        Submit
                                    </Button>

                                </Box>
                            </form>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>

        </Container >
    );
}