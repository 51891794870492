import { useState } from "react";
import { TextField, Button, Typography, Grid, Box, Container, Paper, Hidden } from "@mui/material";
import axios from "axios";

export default function Invoice() {
    const [data, setData] = useState({
        InvoiceNo: '',

        Buyer: '',

        DriverName: '',

        Address: '',

        InvoiceDate: '',

        Seller: '',

        VehicleNo: '',

        Amount: '',

        Tax: '',

        NetAmount: '',
    });
    const [DateInputClick, setDateInputClick] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post('https://localhost:5000/api/InvoiceForm/Post', data).then((res) => console.log(res))
        //
    };

    return (
        <Container style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Paper elevation={6} style={{ maxWidth: '580px' }}>
                <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ height: "100%" }}
                >
                    <Grid item xs={12} md={4} >
                        <Box sx={{ p: 2 }}>
                            <Typography variant="h4" align="left" mb={4} mt={3}>
                                Inovice Form
                            </Typography>
                            <form onSubmit={handleSubmit}>

                                <Box>
                                    <Grid container spacing={1}>

                                        <Grid item xs={12} sm={6} lg={6} md={6}>

                                            <Grid item xs={12} sm={12} lg={12} md={12}>
                                                <TextField
                                                    fullWidth
                                                    label="Invoice No"
                                                    value={data.InvoiceNo}
                                                    onChange={(e) => setData({ ...data, InvoiceNo: e.target.value })}
                                                    margin="normal"
                                                    type="number"
                                                    required
                                                    size="small"
                                                />
                                            </Grid>


                                            <Grid item xs={12} sm={12} lg={12} md={12}>
                                                <TextField
                                                    fullWidth
                                                    label="Buyer"
                                                    value={data.Buyer}
                                                    onChange={(e) => setData({ ...data, Buyer: e.target.value })}
                                                    margin="normal"
                                                    type="text"
                                                    required
                                                    size="small"
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={12} lg={12} md={12}>
                                                <TextField
                                                    fullWidth
                                                    label="Driver Name"
                                                    value={data.DriverName}
                                                    onChange={(e) => setData({ ...data, DriverName: e.target.value })}
                                                    margin="normal"
                                                    type="text"
                                                    required
                                                    size="small"
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} lg={12} md={12}>
                                                <TextField
                                                    fullWidth
                                                    label="Amount"
                                                    value={data.Amount}
                                                    onChange={(e) => setData({ ...data, Amount: e.target.value })}
                                                    margin="normal"
                                                    type="number"
                                                    required
                                                    size="small"
                                                />
                                            </Grid>

                                        </Grid>

                                        <Grid item xs={12} sm={6} md={6} lg={6}>
                                            <Grid item xs={12} sm={12} lg={12} md={12}>
                                                <TextField
                                                    fullWidth
                                                    label="Invoice Date"
                                                    value={data.InvoiceDate}
                                                    onChange={(e) => setData({ ...data, InvoiceDate: e.target.value })}
                                                    margin="normal"
                                                    onClick={() => setDateInputClick(true)}
                                                    onBlur={() => setDateInputClick(false)}
                                                    type={DateInputClick ? 'date' : 'text'}
                                                    required
                                                    size="small"
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} lg={12} md={12}>
                                                <TextField
                                                    fullWidth
                                                    label="Seller"
                                                    value={data.Seller}
                                                    onChange={(e) => setData({ ...data, Seller: e.target.value })}
                                                    margin="normal"
                                                    type="text"
                                                    required
                                                    size="small"
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={12} lg={12} md={12}>
                                                <TextField
                                                    fullWidth
                                                    label="Vehicle No."
                                                    value={data.VehicleNo}
                                                    onChange={(e) => setData({ ...data, VehicleNo: e.target.value })}
                                                    margin="normal"
                                                    type="text"
                                                    required
                                                    size="small"
                                                />
                                            </Grid>







                                            <Grid item xs={12} sm={12} lg={12} md={12}>
                                                <TextField
                                                    fullWidth
                                                    label="Tax"
                                                    value={data.Tax}
                                                    onChange={(e) => setData({ ...data, Tax: e.target.value })}
                                                    margin="normal"
                                                    type="number"
                                                    required
                                                    size="small"
                                                />
                                            </Grid>



                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12} lg={12} md={12}>
                                        <TextField
                                            fullWidth
                                            label="Net Amount"
                                            value={data.NetAmount}
                                            onChange={(e) => setData({ ...data, NetAmount: e.target.value })}
                                            margin="normal" type="number" required
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} lg={12} md={12}>
                                        <TextField
                                            fullWidth
                                            label="Address"
                                            value={data.Address}
                                            onChange={(e) => setData({ ...data, Address: e.target.value })}
                                            margin="normal"
                                            type="text"
                                            multiline
                                            minRows={2.5}
                                            maxRows={2.5}
                                            required
                                            size="small"
                                        />
                                    </Grid>
                                    <Button variant="contained" type="submit" fullWidth sx={{ mt: 3.5, mb: 2 }}>
                                        Submit
                                    </Button>

                                </Box>
                            </form>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>

        </Container >
    );
}