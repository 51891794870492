import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'

const Footer = () => {
    return (
        <>
            <Box style={{ width: '100vw', background: 'rgb(225 225 225)', marginTop: 120, boxShadow: 'rgb(0, 0, 0) 0px 8px 11px 1px' }}>
                <Container style={{ paddingLeft: 20, paddingRight: 20 }}> <Grid container spacing={3} >
                    <Grid item lg={4} md={4} sm={6} xs={12}>

                        <Typography paragraph fontSize={20} fontWeight={700} fontFamily="impact" style={{ letterSpacing: 1 }}>Shri Chindipir</Typography>
                        <Typography paragraph fontSize={14} fontWeight={600} mt={-2.5} color="red" fontFamily="monospace">
                            Sugarcane Supplier</Typography>
                        <Typography paragraph fontSize={12}>
                            86032, 0265, 8005, 10001, 671, <br />
                            Athani road, Chindipir Temple, Shedbad, Dist. - Belbaum 590001
                            <br />
                            Karnataka
                            <br />
                            Contact :   +91 84313 22717
                            <br />
                            E-mail :    chindipirsugarcane@gmail.com
                        </Typography>
                        <img
                            srcSet="./img/bt-img.jfif"
                            src="./img/bt-img.jfif"
                            alt='as'
                            loading="lazy"
                            style={{ maxWidth: '100%', minWidth: 140 }} />
                    </Grid>
                    <Grid item lg={4} md={4} sm={6} xs={11.5}>
                        {<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d736.2302063830272!2d74.64368822478995!3d16.82189219460645!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc1230773dff2e9%3A0xa71a5edeb52b2e4f!2sInnovative%20Computer!5e0!3m2!1sen!2sin!4v1696220380363!5m2!1sen!2sin" height="250" style={{ border: 0, maxWidth: '96vw', width: '100%' }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>}
                        <Typography paragraph fontSize={13} style={{ background: '#fff', padding: 2 }}>
                            {/* Developed : Innovative Information Technology, Miraj.<br /> */}
                            <img src="./img/support.png" style={{ maxWidth: 30, minWidth: 20 }} />Technical Support :
                            +91-94224 09492, +91-98349 66008</Typography>
                    </Grid>    <Grid item lg={4} md={4} sm={12} xs={12}>

                    </Grid>
                </Grid>

                </Container>
                <Box style={{ background: '#2196F3', display: 'flex', width: '100vw', justifyContent: 'center', padding: '20px 0px', marginTop: '20px' }}>
                    <Typography variant="h5" color="black" fontSize={13}>
                        Copyright Shir Chindipir Sugarcane Supplier
                    </Typography>
                </Box>
            </Box >

        </>
    )
}

export default Footer