import { useState } from "react";
import { TextField, Button, Typography, Grid, Box, Container, Paper, Hidden } from "@mui/material";

export default function Enquiry() {
    const [data, setData] = useState({
        invoiceNo: '',
        date: '',
        nurName: '',
        subName: '',
        vehicleNo: '',
        productNo: '',
        weight: '',
        price: '',
        cuttingCharges: '',
        transportCharges: ''
    });

    const [DateInputClick, setDateInputClick] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        //
    };

    return (
        <Container style={{ height: '100vh', display: 'flex', alignItems: 'center' }} >
            <Paper elevation={6} >
                <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ height: "100%" }}
                >
                    <Grid item xs={12} md={4} >
                        <Box sx={{ p: 2 }}>
                            <Typography variant="h4" align="left" mb={4} mt={3}>
                                Enquiry Form
                            </Typography>
                            <form onSubmit={handleSubmit}>
                                <Grid container spacing={2}>
                                    <Hidden only={['xs', 'sm']}>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <Box sx={{ display: "flex", justifyContent: "center" }}>
                                                <img
                                                    src="./img/13.jpg"

                                                    style={{ maxWidth: "100%" }}
                                                />
                                            </Box>
                                        </Grid>
                                    </Hidden>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sm={6} md={6}>
                                                <TextField
                                                    fullWidth
                                                    label="Invoice No."
                                                    value={data.invoiceNo}
                                                    onChange={(e) => setData({ ...data, invoiceNo: e.target.value })}
                                                    margin="normal"
                                                    type="number"
                                                    required
                                                    size="small"
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6}>
                                                <TextField
                                                    fullWidth
                                                    label="Date"
                                                    value={data.date}
                                                    onChange={(e) => setData({ ...data, date: e.target.value })}
                                                    margin="normal"
                                                    onClick={() => setDateInputClick(true)}
                                                    onBlur={() => setDateInputClick(false)}
                                                    type={DateInputClick ? 'date' : 'text'}
                                                    required
                                                    size="small"
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={6} md={6}>
                                                <TextField
                                                    fullWidth
                                                    label="Nur. Name"
                                                    value={data.nurName}
                                                    onChange={(e) => setData({ ...data, nurName: e.target.value })}
                                                    margin="normal"
                                                    required
                                                    size="small"
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6}>
                                                <TextField
                                                    fullWidth
                                                    label="Sub. Name"
                                                    value={data.subName}
                                                    onChange={(e) => setData({ ...data, subName: e.target.value })}
                                                    margin="normal"
                                                    required
                                                    size="small"
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6}>
                                                <TextField
                                                    fullWidth
                                                    label="Vehicle No."
                                                    value={data.vehicleNo}
                                                    onChange={(e) => setData({ ...data, vehicleNo: e.target.value })}
                                                    margin="normal"
                                                    type="number"
                                                    required
                                                    size="small"
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={6} md={6}>
                                                <TextField
                                                    fullWidth
                                                    label="Product No."
                                                    value={data.productNo}
                                                    onChange={(e) => setData({ ...data, productNo: e.target.value })}
                                                    margin="normal"
                                                    type="number"
                                                    required
                                                    size="small"
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={6} md={6}>
                                                <TextField
                                                    fullWidth
                                                    label="Weight in K.G."
                                                    value={data.weight}
                                                    onChange={(e) => setData({ ...data, weight: e.target.value })}
                                                    margin="normal"
                                                    type="number"
                                                    required
                                                    size="small"
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={6} md={6}>
                                                <TextField
                                                    fullWidth
                                                    label="Price"
                                                    value={data.price}
                                                    onChange={(e) => setData({ ...data, price: e.target.value })}
                                                    margin="normal"
                                                    type="number"
                                                    required
                                                    size="small"
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={6} md={6}>
                                                <TextField
                                                    fullWidth
                                                    label="Cutting Charges"
                                                    value={data.cuttingCharges}
                                                    onChange={(e) => setData({ ...data, cuttingCharges: e.target.value })}
                                                    margin="normal"
                                                    type="number"
                                                    required
                                                    size="small"
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={6} md={6}>
                                                <TextField
                                                    fullWidth
                                                    label="Transport Charges"
                                                    value={data.transportCharges}
                                                    onChange={(e) => setData({ ...data, transportCharges: e.target.value })}
                                                    margin="normal"
                                                    type="number"
                                                    required
                                                    size="small"
                                                />
                                            </Grid>  </Grid>
                                        <Button variant="contained" type="submit" fullWidth sx={{ mt: 3.5, mb: 2 }}>
                                            Submit
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>

        </Container >
    );
}