import React from "react";
import App from "./App.jsx";
import * as ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import Enquiry from "./components/forms/Enquiry.jsx";
import Vehicle from "./components/forms/Vehicle.jsx";
import Invoice from "./components/forms/Invoice.jsx";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/Enquiry",
    element: <Enquiry />,
  },
  {
    path: "/Vehicle",
    element: <Vehicle />,
  },
  {
    path: "/Invoice",
    element: <Invoice />,
  },
]);
ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
