import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Menu,
  Typography,
  ListItemButton,
  Tooltip,
  IconButton,
  Divider,
  ListItemIcon,
  Modal,
  Hidden,
  ButtonGroup,
} from "@mui/material";
import {
  AdminPanelSettingsRounded,
  Logout,
  ManageAccountsRounded,
  MoreVert,
  Person,
  Settings,
  SupervisedUserCircleRounded,
  VerifiedUserRounded,
} from "@mui/icons-material";
import { AccountCircleRounded } from "@mui/icons-material";
// import { Link } from 'react-router-dom';

import MessageModal from "./MessageModal";
import { Link } from "react-router-dom";

// import Grid from '@mui/material/Grid';

function Header() {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 430,
    minWidth: 260,
    bgcolor: "background.paper",
    borderRadius: 2,
    border: "none",
    boxShadow: 2,
    p: 2.5,
    outline: "none",
  };

  const [openModel, setOpenModel] = useState(false);

  const handleOpenModel = () => setOpenModel(true);
  const handleCloseModel = () => setOpenModel(false);

  useEffect(() => {
    setOpenModel(true);
  }, []);

  const [LoginMenu, setLoginMenu] = React.useState(null);
  // const [enqModal, setenqModal] = React.useState(false);
  const open = Boolean(LoginMenu);
  const handleClick = (event) => {
    setLoginMenu(event.currentTarget);
  };
  const MenuhandleClose = () => {
    setLoginMenu(null);
  };

  return (
    <>
      <Topbar />
      <AppBar
        position="relative"
        variant="elevation"
        color="inherit"
        style={{ width: "100vw", marginTop: 45 }}
      >
        <Toolbar variant="regular" style={{ minHeight: "70px" }}>
          <Box sx={{ display: "flex", width: "100%", alignItems: "center" }}>
            <img
              srcSet="./img/scs.jpg"
              src="./img/scs.jpg"
              alt="as"
              loading="lazy"
              style={{ maxWidth: 200, minWidth: 160 }}
            />
            <Box
              style={{
                flex: 1,
                justifyContent: "flex-end",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Hidden smDown>
                {" "}
                <Button
                  onClick={handleOpenModel}
                  size="medium"
                  style={{ marginTop: 10, marginRight: "8vw" }}
                >
                  <img src="./img/enqire.jfif" width="150" />
                </Button>
              </Hidden>
              <Modal open={openModel} onClose={handleCloseModel}>
                <Box sx={style}>
                  <MessageModal />
                </Box>
              </Modal>
            </Box>
          </Box>
          <Hidden smUp>
            <Box style={{ marginLeft: 0 }}>
              {/* <MessageModal enqModal={enqModal} /> */}
              <IconButton
                onClick={handleClick}
                size="large"
                sx={{ ml: 2 }}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <AccountCircleRounded fontSize="large" />
              </IconButton>

              <Menu
                Menu={LoginMenu}
                id="account-menu"
                open={open}
                onClose={MenuhandleClose}
                onClick={MenuhandleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 11,
                    ml: 1,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "top" }}
              >
                {/* <Link to='/Enquiry'>  <MenuItem onClick={handleClose}>
      <ListItemIcon>
        <AccountCircleRounded fontSize="large" />
      </ListItemIcon>
      Buyer Login
    </MenuItem></Link> */}
                <a href="https://app.chindipirsugercane.online">
                  <MenuItem onClick={MenuhandleClose}>
                    <ListItemIcon>
                      <AccountCircleRounded fontSize="large" />
                    </ListItemIcon>
                    Buyer Login
                  </MenuItem>
                </a>

                <a href="https://app.chindipirsugercane.online">
                  <MenuItem onClick={MenuhandleClose}>
                    <ListItemIcon>
                      <AccountCircleRounded fontSize="large" />
                    </ListItemIcon>
                    Admin Login
                  </MenuItem>
                </a>
              </Menu>

              {/* <a href='https://app.chindipirsugercane.online'><Button variant="contained" color="error" size='large' style={{ fontSize: '13px', fontWeight: 700, fontFamily: "inherit" }}>Login</Button> */}
            </Box>
          </Hidden>

          {/* </ Box > */}
          {/* <ImageListItem> */}

          {/* </ImageListItem> */}
        </Toolbar>
      </AppBar>
    </>
  );
}

export const Topbar = () => {
  return (
    <AppBar
      position="fixed"
      variant="outlined"
      color="inherit"
      style={{ left: 0, width: "100vw", background: "#efefef" }}
    >
      <Toolbar variant="dense" sx={{ flexGrow: 1, maxHeight: "45px" }}>
        <Grid container spacing={3} mt={-3}>
          <Hidden smDown>
            {" "}
            <Grid item lg={9} md={7} sm={6} xs={12} mt={2}>
              <Typography
                paragraph
                fontSize="12px"
                fontFamily="system-ui"
                ml={5}
                flex={1}
                textAlign="left"
                fontWeight={600}
              >
                Support : +91 84311 91109 | Contact : +91 99165 60215
              </Typography>
            </Grid>
          </Hidden>
          <Hidden smUp>
            <Grid
              item
              lg={9}
              md={7}
              sm={12}
              xs={12}
              style={{ height: 45 }}
              mt={4.5}
            >
              <Typography
                paragraph
                fontSize="11px"
                fontFamily="system-ui"
                m="auto"
                flex={1}
                textAlign="center"
                fontWeight={600}
              >
                Support : +91 84311 91109 | Contact : +91 99165 60215
              </Typography>{" "}
            </Grid>
          </Hidden>
          <Grid item lg={3} md={5} sm={6} mt={1}>
            <Hidden smDown>
              {/* <ButtonGroup variant="outlined" aria-label="outlined button group">
                <Button size="small">
                  <AccountCircleRounded fontSize="large" />
                  Buyer Login
                </Button>
                <Button size="small">
                  <AccountCircleRounded fontSize="large" />
                  Admin Login
                </Button>
              </ButtonGroup> */}

              {/* <ButtonGroup variant="text" aria-label="text button group" sx={{ width: '304.5px' }}> */}
              <Button variant="text" color="success">
                <SupervisedUserCircleRounded fontSize="medium" />
                <Typography fontSize={12} width="auto" ml={1}>
                  Buyer Login
                </Typography>
              </Button>
              <a href="https://app.chindipirsugercane.online">
                {" "}
                <Button variant="text" color="success">
                  <ManageAccountsRounded fontSize="medium" />
                  <Typography fontSize={12} width="auto" ml={1}>
                    Admin Login
                  </Typography>
                </Button>
              </a>
              {/* </ButtonGroup> */}
            </Hidden>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
