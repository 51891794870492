import React, { useEffect, useState } from 'react'

import { Card, CardMedia, CardContent, Typography, CardActions, Button, Box, Container, Grid, Paper, Divider } from '@mui/material'
import '../css/Products.css'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import { AddBox } from '@mui/icons-material'

const Products = () => {
    const [data, setdata] = useState([
        {
            img: './img/s1.JPG',
            title: 'White Natural Fresh Sugarcane',
            subtitle: 'Processing Type: Natural',
            info: 'Sugarcane juice has been one of the most popular summer drink in India. Every child in India has some or the,'
        },
        {
            img: './img/s2.JPG',
            title: 'Fresh Sugarcane',
            subtitle: `Processing Type: Natural`,
            info: 'Sugarcane juice has been one of the most popular summer drink in India. Every child in India has some or the,'
        },

    ])
    // useEffect(() => {
    //     setdata()
    // })
    return (
        <>
            <Grid container columnSpacing={2} rowSpacing={2}>
                {
                    data.map((data, i) => {
                        return (
                            <Grid item lg={12} md={12} sm={6} xs={12}>
                                <ProductsCard
                                    key={i}
                                    img={data.img}
                                    title={data.title}
                                    subtitle={data.subtitle}
                                    info={data.info}
                                />
                            </Grid>
                        )
                    })
                }
            </Grid>
            {/* < ProductInfo /> */}
        </>
    )
}


export const ProductsCard = (props) => {
    return (
        <Card sx={{ maxWidth: '100%', mb: 2 }}>

            <CardContent sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Grid container rowSpacing={1} columnSpacing={1}>
                    <Grid lg={4.5} md={5} sm={12} xs={12} sx={{ pl: 2, pb: 2, pt: 1 }}>
                        <img src={props.img} className='lImg' />
                    </Grid>
                    <Grid lg={7.5} md={7} sm={12} xs={12} sx={{ pl: 2 }}>
                        <Typography gutterBottom variant="h6" fontSize={19} component="div">
                            {props.title}

                        </Typography>
                        <Typography paragraph fontSize={15} color="red">
                            {props.subtitle}
                        </Typography>
                        <Typography paragraph fontSize={15}>

                            {props.info}
                        </Typography>
                    </Grid>

                </Grid>

            </CardContent>

        </Card>
    )
}

export const ProductInfo = () => {
    return (
        <Grid container columnSpacing={1} rowSpacing={1} style={{ maxWidth: '100%', }}>
            <Grid item lg={4.5} md={4.5} sm={4} xs={12} justifySelf="center">

                <Paper elevation={2} style={{ padding: "16px 15px 6px 15px" }}>

                    <Typography gutterBottom variant="h6" fontSize={19} style={{ fontFamily: "system-ui", fontWeight: 600, color: "darkblue" }}>
                        Shas ai

                    </Typography>

                    <Typography paragraph fontSize={15}>

                        djsddjsd ohd aoid osid sioidaoid osid sioid osid osdh sidhsidhs dsdhs doshdbcod hdio hcbahdosa idh odncaod hisdcb shidhncishdiu hudhbcgdciuhi uhaduhadcbiudhiu dhucidig ausdg iusgdcbuid giuadhuasddviasudabcgd audycba cia adicb
                    </Typography>
                </Paper>
            </Grid>
            <Grid item lg={7.5} md={7.5} sm={8} xs={12} justifySelf="center">
                <Paper elevation={2} style={{ padding: "16px 15px 6px 15px" }}>
                    <Typography gutterBottom variant="h6" fontSize={19} style={{ fontFamily: "system-ui", fontWeight: 600, color: "darkblue" }}>
                        Shas ai

                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item lg={6} md={6} sm={6} xs={12} justifySelf="center">

                            <Typography paragraph fontSize={15}>

                                hiu dhucidig ausdg iusgdcbuid giuadhuasbuid giuadhuasddviasudabcgd audycba cia adicb
                            </Typography>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12} justifySelf="center">

                            <Typography paragraph fontSize={15}>

                                hiu dhucidig ausdg iusgdcbuid giuadhuasbuid giuadhuasddviasudabcgd audycba cia adicb                            </Typography>
                        </Grid>


                    </Grid>
                    <Divider style={{ background: "#000", height: 1, marginBottom: 20 }} />
                    <Typography paragraph fontSize={14.5} >

                        uhaduha uhaduhadcbiudhiu dhucidig haduha uhaduhadcbiudhiu dhucidig ausdg iusgdcbuid giuadhuasbuid giuadhuasddviasudabcgd audycba cia adicb
                    </Typography>
                </Paper>
            </Grid>
        </Grid>

    )
}

export default Products